/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {
  stripslashes,
  hideLoader,
  showLoader,
  removeOrderDateTime,
  showAlert,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET_REQUESTPAGEDATA,GET_STATIC_BLOCK } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import axios from "axios";

/*** DEV SK***/
import {
  appId,
  reservationId,
  queueId,
  CountryTxt,
  apiUrlV2,
  deliveryId,
  apiUrl
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_ALL_OUTLETS,
} from "../../actions";
import cookie from "react-cookies";
var qs = require("qs");


class Queue extends Component {
  constructor(props) {
    super(props);

    var Maxdate = new Date();
    Maxdate.setFullYear(Maxdate.getFullYear() - 10);

    this.state = {
      outletlist: [],
      queue_outlet_id: "",
      queue_outlet_txt: "",
      queue_selected_outlet: "",
      queue_outlet_postalcode: "",
      queue_outlet_name: "",
      queue_outletaddress_line: "",
      queue_outlet_address: "",
      adultValue: 0,
      childValue: 0,
      outletadultLimit: "",
      outletchildLimit: "",
      outletpaxLimit: "",
      corner_seat:'no',
      ResAllOutletDropDownHtml: "",
      mobile_number:
        typeof cookie.load("UserMobile") != "undefined" &&
        cookie.load("UserMobile") != ""
          ? cookie.load("UserMobile")
          : "",
    };

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
  }

  async componentDidMount() {
    cookie.save("defaultAvilablityId", queueId, { path: "/" });
    await this.props.getAllOutlets();

    if (
      typeof cookie.load("queue_phoneno") != "undefined" &&
      typeof cookie.load("queue_phoneno") != ""
    ) {
      this.setState({ queue_phoneno: cookie.load("queue_phoneno") });
    }
    if (
      typeof cookie.load("queue_selected_outlet") != "undefined" &&
      typeof cookie.load("queue_selected_outlet") != ""
    ) {
      this.setState({ queue_selected_outlet: cookie.load("queue_selected_outlet") });
    }

    if (
      typeof cookie.load("adultValue") != "undefined" &&
      typeof cookie.load("adultValue") != ""
    ) {
      this.setState({ adultValue: cookie.load("adultValue") });
    }

    if (
      typeof cookie.load("childValue") != "undefined" &&
      typeof cookie.load("childValue") != ""
    ) {
      this.setState({ childValue: cookie.load("childValue") });
    }

    $(".error_div_cls_limit").hide();
    $(".error_div_cls_limit_outlet").hide();
    $(".error_div_cls").hide();

    $(".error_mobile_div").html("");
    $(".error_mobile_div").hide();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.alloutletslist !== nextProps.alloutletslist) {
      var ResOutletHtml = "<option value=''> Select Outlet </option>";
      var outletArr = nextProps.alloutletslist;
      if (Object.keys(outletArr).length > 0) {
        if (outletArr.length == 1) {
          var ResOutletHtml = "";
        }
        outletArr.map(
          (loadData) =>
            (ResOutletHtml +=
              "<option value='" +
              loadData.outlet_id +
              "~" +
              loadData.outlet_postal_code +
              "~" +
              stripslashes(loadData.outlet_name).replace("'", "`") +
              "~" +
              stripslashes(loadData.outlet_address_line1) +
              "~" +
              loadData.outlet_delivery_timing +
              "~" +
              loadData.outlet_adult_limit +
              "~" +
              loadData.outlet_child_limit +
              "~" +
              loadData.outlet_max_pax_limit +
              "' >" +
              stripslashes(loadData.outlet_name) +
              "</option>")
        );

        if (outletArr.length == 1) {
          var addrsTxt =
            stripslashes(outletArr[0].outlet_address_line1) +
            " " +
            CountryTxt +
            ", " +
            outletArr[0].outlet_postal_code;

          this.setState({
            queue_outlet_id: outletArr[0].outlet_id,
            queue_outlet_postalcode: outletArr[0].outlet_postal_code,
            queue_outlet_name: stripslashes(outletArr[0].outlet_name),
            queue_outletaddress_line: stripslashes(
              outletArr[0].outlet_address_line1
            ),
            queue_outlet_address: addrsTxt,
            outletadultLimit: outletArr[0].outlet_adult_limit,
            outletchildLimit: outletArr[0].outlet_child_limit,
            outletpaxLimit: outletArr[0].outlet_max_pax_limit,
          });
        }
      }
      var outletDropDown = Parser(ResOutletHtml);
      this.setState({
        outletlist: nextProps.alloutletslist,
        ResAllOutletDropDownHtml: outletDropDown,
      });
    }

  }


  updateAdultQty(type) {
    var queue_outlet_id = this.state.queue_outlet_id;
    var currentAdult = this.state.adultValue;
    var currentChild = this.state.childValue;
    var maxAdult = this.state.outletadultLimit;
    var paxlimit = this.state.outletpaxLimit;
    var newValue = "";

    if (queue_outlet_id === "") {
      $(".error_div_cls_limit_outlet").show();
      setTimeout(function () {
        $(".error_div_cls_limit_outlet").hide();
      }, 1000);
    } else {
      if (type === "increment") {
        if (maxAdult != null) {
          if (
            parseInt(currentAdult) < maxAdult &&
            parseInt(currentAdult) + parseInt(currentChild) < paxlimit
          ) {
            $(".error_div_cls_limit").hide();
            newValue = parseInt(currentAdult) + 1;
          } else {
            $(".error_div_cls_limit").show();
            newValue = parseInt(currentAdult);
          }
        } else {
          newValue = parseInt(currentAdult) + 1;
        }
      } else if (type === "decrement") {
        $(".error_div_cls_limit").hide();
        if (parseInt(currentAdult) > 0) {
          newValue = parseInt(currentAdult) - 1;
        } else {
          newValue = 0;
        }
      }
      $(".error_div_cls").hide();
      this.setState({ adultValue: newValue });
      setTimeout(function () {
        $(".error_div_cls_limit").hide();
      }, 1000);
    }
  }

  updateChildtQty(type) {
    var currentChild = this.state.childValue;
    var currentAdult = this.state.adultValue;
    var maxChild = this.state.outletchildLimit;
    var paxlimit = this.state.outletpaxLimit;
    var newValue = "";
    if (type === "increment") {
      if (maxChild != null) {
        if (
          parseInt(currentChild) < maxChild &&
          parseInt(currentAdult) + parseInt(currentChild) < paxlimit
        ) {
          $(".error_div_cls_limit").hide();
          newValue = parseInt(currentChild) + 1;
        } else {
          $(".error_div_cls_limit").show();
          newValue = parseInt(currentChild);
        }
      } else {
        newValue = parseInt(currentChild) + 1;
      }
    } else if (type === "decrement") {
      $(".error_div_cls_limit").hide();
      if (parseInt(currentChild) > 0) {
        newValue = parseInt(currentChild) - 1;
      } else {
        newValue = 0;
      }
    }
    $(".error_div_cls").hide();
    this.setState({ childValue: newValue });
    setTimeout(function () {
      $(".error_div_cls_limit").hide();
    }, 1000);
  }


  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  handleChange(event) {
    if (event.target.name === "queue_outlet_id") {
      this.resetErrorClass();
      showLoader("datepickers_main_div", "class");
      var ouLtvl = event.target.value;

      this.setState({ queue_selected_outlet: ouLtvl });
      var outletIdvl = "",
        outletAddrsLn = "",
        outletAddrs = "",
        outletPostCd = "",
        outletName = "",
        errorInfo = "block",
        temHlval = "",
        outletTatTm = "",
        outletadultLimit = "",
        outletchildLimit = "",
        outletpaxLimit = "";

      if (ouLtvl !== "") {
        var splitedOutletArr = ouLtvl.split("~");
        outletIdvl = splitedOutletArr[0];
        outletPostCd = splitedOutletArr[1];
        outletName = splitedOutletArr[2];
        outletAddrsLn = splitedOutletArr[3];
        outletTatTm = splitedOutletArr[4];
        outletadultLimit = splitedOutletArr[5];
        outletchildLimit = splitedOutletArr[6];
        outletpaxLimit = splitedOutletArr[7];
        outletAddrs = outletAddrsLn + " " + CountryTxt + ", " + outletPostCd;
        errorInfo = "none";

        hideLoader("datepickers_main_div", "class");
      }

      this.setState({
        queue_outlet_id: outletIdvl,
        queue_outlet_postalcode: outletPostCd,
        queue_outlet_name: outletName,
        queue_outletaddress_line: outletAddrsLn,
        queue_outlet_address: outletAddrs,
        datePickershow: 1,
        outletadultLimit: outletadultLimit,
        outletchildLimit: outletchildLimit,
        outletpaxLimit: outletpaxLimit,
      });

      var seletedOutletId = outletIdvl;

      var orderHandled = outletName;

      var dineInfo = [];
      dineInfo["orderOutletId"] = outletIdvl;
      dineInfo["orderOutletName"] = stripslashes(outletName);
      dineInfo["orderPostalCode"] = outletPostCd;
      dineInfo["orderTAT"] = outletTatTm;
      dineInfo["orderHandled"] = orderHandled;
      dineInfo["orderHandledByText"] = orderHandled;
      dineInfo["defaultAvilablityId"] = queueId;

      this.setState({
        seletedAvilablityId: queueId,
        seletedOutletId: outletIdvl,
      });

      var dineInfo = this.state.dineInfo;
      if (seletedOutletId !== "") {
      } else {
        $(".outlet_error").html(
          '<span class="error"> Please choose one outlet.</span>'
        );
      }

      document.getElementById("error_info_div").style.display = errorInfo;
    }


    if (event.target.name === "mobile_number") {
      this.setState({ mobile_number: event.target.value });
    }
  }

  checkcornerseat(type) {
    var isSame = this.state.corner_seat;
    var chkBox = false;
    if (isSame === "yes") {
      chkBox = true;
    }
    return chkBox;
  }

  changecornerseat() {
    var isSame = this.state.corner_seat;
    if (isSame === "yes") {
      this.setState({
        corner_seat: "no",
      });
    } else {
      this.setState({
        corner_seat: "yes" 
      }
      );
    }
  }


  submit_queue() {
      $(".error_mobile_div").hide();

      if (this.state.mobile_number === "") {
        $(".error_mobile_div").html("Enter mobile number");
        $(".error_mobile_div").show();
        return false;
      } else {
        const phonenumberPattern = /^[0-9]{6,14}$/;
        var mobile_no = this.state.mobile_number;

        if (!mobile_no.match(phonenumberPattern)) {
          $(".error_mobile_div").html("Please enter valid Mobile Number.");
          $(".error_mobile_div").show();
          return false;
        }
      }

      var adultValue = this.state.adultValue;
      var childValue = this.state.childValue;

      if (parseInt(adultValue) === 0 && parseInt(childValue) === 0) {
        $(".error_div_cls").show();
        return false;
      }

      if (this.state.queue_outlet_id === "") {
        $("#error_info_div").show();
        return false;
      }

      cookie.save("queue_adult", this.state.adultValue, { path: "/" });
      cookie.save("queue_child", this.state.childValue, { path: "/" });
      cookie.save("queue_outlet_id", this.state.queue_outlet_id, {
        path: "/",
      });
      cookie.save(
        "queue_outlet_name",
        this.state.queue_outlet_name,
        { path: "/" }
      );
      cookie.save(
        "queue_selected_outlet",
        this.state.queue_selected_outlet,
        { path: "/" }
      );

      cookie.save("queue_mobile_no", this.state.mobile_number, {
        path: "/",
      });
    showLoader("continue", "class");

    var postObject = {};

    postObject = {
      app_id: appId,
      availability_id: cookie.load("defaultAvilablityId"),
      queue_outlet_id: this.state.queue_outlet_id,
      queue_no_of_adult_pax: this.state.adultValue,
      queue_no_of_children_pax: this.state.childValue,
      customer_mobile_no: this.state.mobile_number,
      queue_outlet_name: this.state.queue_outlet_name,    
      queue_corner_seat: this.state.corner_seat,              
    };

    axios
      .post(apiUrl + "reservation/reservation_queue", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status == "ok") {
          hideLoader("continue", "class");
          var localOrderNo = res.data.common.local_order_no;
          var queue_number = res.data.common.queue_number;          
          cookie.save("QueueOrderlocalId",localOrderNo, { path: "/" }) ;  
          cookie.save("QueueOrdernumber",res.data.common.queue_number, { path: "/" }) ;                            
          this.props.history.push("/thankyou/" + queue_number);                                    
        } else if (res.data.status == "error") {
          hideLoader("continue", "class");
          if (res.data.form_error !== undefined) {
            showAlert("Error", res.data.form_error);
          } else {
            showAlert("Error", res.data.message);
          }
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      })
      .catch((error) => {
        $.magnificPopup.close();
        showAlert("Error", 'Error code: 1004 Oops! Unable to processing your request. Please try again.');
        $.magnificPopup.open({
          items: {
            src: ".warining_popup",
          },
          type: "inline",
        });
        return false;
      });


  }

  resetErrorClass() {
    $(
      ".choose_event_data, .date_list_maindiv, .breaktime_main_div"
    ).removeClass("pkgerror_out");
    $(
      ".choose_event_data, .date_list_maindiv, .breaktime_main_div"
    ).removeClass("bdr_bf_select");
    $(".choose_event_data, .date_list_maindiv, .breaktime_main_div").addClass(
      "bdr_bf_select"
    );
  }

  render() {
    let defaultAvilTyId = cookie.load("defaultAvilablityId");
    var settingsArr = this.props.globalsettings;
    var corner_seat = 0;
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        corner_seat =
          settingsArr[0].result_set.client_enable_queue_corner_seat;
      }
    }

    return (
      <div>
        <div className="common-top-div reservation-main-div">
          <Header />
          <div className="container innersection_wrap">
            <div className="queue_step_one">
              <h3>GET A QUEUE NUMBER</h3>
              <div className="reservation-innermain">
                <div className="resin-title">
                  <h4>Enter your details here</h4>
                </div>
                    
                <div className="reservation-cnt-main">
                  <div className="form-group">
                    <div id="reservation">
                      <div className="choose-outlet-list">
                        <div
                          id="no_outlet_info"
                          className="errorInfo-div"
                        >
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          <span id="info_span">
                            {" "}
                            Sorry!. Outlets didn't available for
                            catering..
                          </span>
                        </div>
                        <div
                          id="error_info_div"
                          className="errorInfo-div"
                        >
                          <i
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                          <span id="error error_span">
                            {" "}
                            Please select Outlet.
                          </span>
                        </div>
                        {Object.keys(this.props.alloutletslist).length > 1 && (                        
                        <div
                          className="form-group custom_select choose_event_data bdr_bf_select"
                          id="outlet_list_div"
                        >
                          <select
                            value={this.state.queue_selected_outlet}
                            name="queue_outlet_id"
                            className="form-control"
                            onChange={this.handleChange.bind(this)}
                          >
                            {this.state.ResAllOutletDropDownHtml}
                          </select>
                        </div>
                        )}                        
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="text-center">
                      <input
                        type="text" inputMode="numeric" pattern="[0-9]*" 
                        className="proqty_input"
                        name="mobile_number"
                        onChange={this.handleChange.bind(this)}
                        value={this.state.mobile_number}
                        placeholder="Mobile Number"
                      />
                      <div className="error error_mobile_div">
                        Enter Phone Number
                      </div>
                    </div>
                  </div>

                  <div className="form-group reservation-adult-heading">
                    <h5>Choose Your Pax</h5>
                    <div className="reservation-adult-inner flex">
                      <div className="reservation-adult-lhs">
                        <p>Adults</p>
                      </div>
                      <div className="reservation-adult-rhs">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.updateAdultQty.bind(
                              this,
                              "decrement"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            name="adultValue"
                            value={this.state.adultValue}
                            readOnly
                          />
                          <span
                            className="qty_plus"
                            onClick={this.updateAdultQty.bind(
                              this,
                              "increment"
                            )}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="reservation-adult-inner flex">
                      <div className="reservation-adult-lhs">
                        <p>Children&nbsp;&nbsp;&nbsp;</p>
                      </div>
                      <div className="reservation-adult-rhs">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.updateChildtQty.bind(
                              this,
                              "decrement"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="proqty_input"
                            readOnly
                            value={this.state.childValue}
                          />
                          <span
                            className="qty_plus"
                            onClick={this.updateChildtQty.bind(
                              this,
                              "increment"
                            )}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="error error_div_cls_limit_outlet"> Please select outlet</div>
                    <div className="error error_div_cls">Please Add Adult OR Child Count.</div>
                  </div>
                  {corner_seat == 1 && 
                  <div className="form-group">
                    <div className="custom_checkbox resvation-ckbox-main check-left">
                      <input type="checkbox" id="corner_seat" 
                      className="addon_list_single"
                      onChange={this.changecornerseat.bind(
                        this
                      )}
                      checked={this.checkcornerseat(
                        "checkbox"
                      )} /> 
                      <div className="resvation-ckbox">
                        <p>I Dont Mind Counter Seat</p>
                      </div>
                    </div>
                  </div>}

                  


                  <div className="form-group">
                    <div className="res-register-btn">
                      <a href="javascript:void(0)" className="button"
                        onClick={this.submit_queue.bind(this)}
                      >
                        Get My Queue Number Now
                      </a>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* innersection_wrap - end */}
        </div>
        {/* reservation-main-div - end */}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var outletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      outletsArr = state.alloutlets[0].result_set;
    }
  }


  return {
    globalsettings: state.settings,
    alloutletslist: outletsArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getRequestpage: (slug) => {
      dispatch({ type: GET_REQUESTPAGEDATA, slug });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },

  };
};
Queue.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Queue)
);
