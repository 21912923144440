/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import update from "immutability-helper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import Changepassword from "./Changepassword";
import { GET_ACTIVITYCOUNT, GET_CHANGEPASSWORD } from "../../actions";
import { appId } from "../Helpers/Config";
import { showAlert, showLoader, hideLoader } from "../Helpers/SettingHelper";
var qs = require("qs");
var currentAppId = "93EDF976-7242-4864-B57E-7B49306521AC";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: this.props.pageName,
      deliveryTakeaway_orders: "",
      reservation_orders: "",
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
    };
    const inputKeys = [];
    this.props.getActivityCount();
    this.changepassword = this.changepassword.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycountNew).length > 0) {
      if (nextProps.activitycountNew !== this.props.activitycountNew) {
        if (
          nextProps.activitycountNew[0].status &&
          nextProps.activitycountNew[0].result_set
        ) {
          this.setState({
            deliveryTakeaway_orders:
              nextProps.activitycountNew[0].result_set.order,
            reservation_orders:
              nextProps.activitycountNew[0].result_set.reservation_orders,
          });
        }
      }
    } else {
      this.setState({
        order_all: 0,
        overall_orders: 0,
        catering_orders: 0,
        deliveryTakeaway_orders: 0,
        reservation_orders: 0,
      });
    }

    if (nextProps.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (nextProps.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (nextProps.changepassword[0].form_error) {
          var msg = nextProps.changepassword[0].form_error;
        } else {
          var msg = nextProps.changepassword[0].message;
        }

        showAlert("Error", msg.replaceAll("PIN", "Password"));
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  /* Change Password */
  changepassword() {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("UserId"),
      passwordtype: "PIN",
    };
    /*if(new_pin != confirm_pin){
      alert("Password and Confirm password did not match");
      return false;
    }else{*/
      showLoader("change-pass-sbmt", "class");
      this.props.getChangePassword(qs.stringify(postObject));
    //}
  }

  render() {
    var initialSlide = 0;
    if (this.state.activePage === "myorders") {
      initialSlide = 1;
    } else if (this.state.activePage === "rewards") {
      initialSlide = 2;
    } else if (this.state.activePage === "mypromotions") {
      initialSlide = 3;
    }
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      initialSlide: initialSlide,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    var selectedMenuTitle = "My Account";

    if (this.state.activePage === "myaccount") {
      selectedMenuTitle = "Account Details";
    } else if (this.state.activePage === "dashboard") {
      selectedMenuTitle = "Dashboard";
    } else if (this.state.activePage === "myorders") {
      selectedMenuTitle = "My Orders";
    } else if (this.state.activePage === "myreservations") {
      selectedMenuTitle = "My Reservations";
    } else if (this.state.activePage === "referral") {
      selectedMenuTitle = "Refer Friends";
    } else if (this.state.activePage === "rewards") {
      selectedMenuTitle = "My Rewards";
    } else if (this.state.activePage === "mypromotions") {
      selectedMenuTitle = "My Promotions";
    }
    return (
      <>
        <div className="mbacc_mslidersec mbacc_mslider ">
          {/* <Slider {...settingsMyAcc}>
            <div
              className={
                this.state.activePage === "myaccount"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myaccount" title="My Account">
                <span>Account Details</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "myorders"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/myorders" title="My Orders">
                <span>
                  Orders{" "}
                  {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                    <strong className="count_num_t">
                      {this.state.deliveryTakeaway_orders}
                    </strong>
                  )}
                </span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "rewards"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/rewards" title="My Rewards">
                <span>Rewards</span>
              </Link>
            </div>
            <div
              className={
                this.state.activePage === "mypromotions"
                  ? "mbacc_mslide active"
                  : "mbacc_mslide"
              }
            >
              <Link to="/mypromotions" title="My Promotions">
                <span>Promotions</span>
              </Link>
            </div>
            <div className="mbacc_mslide">
              <a href="#change-password-popup" className="open-popup-link">
                Change PIN
              </a>
            </div>
            <div className="mbacc_mslide">
              <a href="/logout">Logout</a>
            </div>
          </Slider> */}

          <div className="hcategory_sec">
            <a href="/" className="hcategory_selected_text disbl_href_action">
              {" "}
              {selectedMenuTitle} <span className="hcategory_trigger"></span>
            </a>
            <div className="hcategory_menu">
              <ul className="hcategory_menulist">
                {/*  <li>
                      <Link to="/dashboard" title="Dashboard">
                        <span>Dashboard</span>
                      </Link>
                    </li> */}
                <li>
                  <Link to="/myaccount" title="My Account">
                    <span>Account Details</span>
                  </Link>
                </li>
                <li>
                  <Link to="/myorders" title="My Orders">
                    <span>
                      Orders{" "}
                      {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                        <strong className="count_num_t">
                          {this.state.deliveryTakeaway_orders}
                        </strong>
                      )}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/myreservations" title="My Reservations">
                    <span>
                      Reservations{" "}
                      {parseFloat(this.state.reservation_orders) > 0 && (
                        <strong className="count_num_t">
                          {this.state.reservation_orders}
                        </strong>
                      )}
                    </span>
                  </Link>
                </li>
                {appId != currentAppId &&
                <li>
                  <Link to="/referral" title="Referral">
                    <span>Referral</span>
                  </Link>
                </li>
                }
                <li>
                  <Link to="/rewards" title="My Rewards">
                    <span>Rewards</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mypromotions" title="My Promotions">
                    <span>Promotions</span>
                  </Link>
                </li>
                {appId != currentAppId &&
                 <li>
                  <Link to="/referral" title="Refer Friends">
                    <span>Refer Friends</span>
                  </Link>
                </li>
                }
                <li>
                  <a href="#change-password-popup" className="open-popup-link">
                    Change Password
                  </a>
                </li>
                <li>
                  <a href="/logout">Logout</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="accmenu_sec">
          <div className="setting_menu_list">
            <ul>
              {/*  <li
                className={
                  this.state.activePage === "dashboard" ? "active" : ""
                }
              >
                <Link to="/dashboard" title="Dashboard">
                  <span>Dashboard</span>
                </Link>
              </li> */}
              <li
                className={
                  this.state.activePage === "myaccount" ? "active" : ""
                }
              >
                <Link to="/myaccount" title="My Account">
                  <span>Account Details</span>
                </Link>
              </li>
              <li
                className={this.state.activePage === "myorders" ? "active" : ""}
              >
                <Link to="/myorders" title="My Orders">
                  <span>
                    Orders{" "}
                    {parseFloat(this.state.deliveryTakeaway_orders) > 0 && (
                      <strong className="count_num_t">
                        {this.state.deliveryTakeaway_orders}
                      </strong>
                    )}
                  </span>
                </Link>
              </li>

              <li
                className={
                  this.state.activePage === "myreservations" ? "active" : ""
                }
              >
                <Link to="/myreservations" title="My Reservations">
                  <span>
                    Reservations{" "}
                    {parseFloat(this.state.reservation_orders) > 0 && (
                      <strong className="count_num_t">
                        {this.state.reservation_orders}
                      </strong>
                    )}
                  </span>
                </Link>
              </li>
              {/* <li
                className={this.state.activePage === "referral" ? "active" : ""}
              >
                <Link to="/referral" title="Referral">
                  <span>Referral</span>
                </Link>
              </li> */}

              <li
                className={this.state.activePage === "rewards" ? "active" : ""}
              >
                <Link to="/rewards" title="My Rewards">
                  <span>Rewards</span>
                </Link>
              </li>
              <li
                className={
                  this.state.activePage === "mypromotions" ? "active" : ""
                }
              >
                <Link to="/mypromotions" title="My Promotions">
                  <span>Promotions</span>
                </Link>
              </li>
               <li
              className={this.state.activePage === "myvouchers" ? "active" : ""}
            >
              <Link to="/myvouchers" title="Vouchers">
                <span>Vouchers</span>
              </Link>
            </li> 
            {appId != currentAppId &&
             <li
              className={this.state.activePage === "referral" ? "active" : ""}
            >
              <Link to="/referral" title="Refer Friends">
                <span>Refer Friends</span>
              </Link>
            </li> 
            }
              <li>
                <a href="#change-password-popup" className="open-popup-link">
                  Change Password
                </a>
              </li>
              <li>
                <a href="/logout">Logout</a>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="change-password-popup"
          className="white-popup mfp-hide popup_sec changepw_popup"
        >
          <div className="pouup_in">
            <div id="form-msg"></div>
            <h3 className="title1 text-center">Change Password</h3>
            <Changepassword
              fields={this.state.fieldschpassword}
              onChange={this.fieldChange}
              onValid={this.changepassword}
              onInvalid={() => console.log("Form invalid!")}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activitycountNew: state.activitycount,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  };
};

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
