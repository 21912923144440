/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { stripslashes, hideLoader } from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { GET_REQUESTPAGEDATA, GET_RES_ORDER_SUBMIT } from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import cookie from "react-cookies";
import moment from "moment";
import { appId, apiUrl, queueId } from "../Helpers/Config";

import tickImg from "../../common/images/tick.png";

class Reservationqueuethankyou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagedetail: "",
      outletName: cookie.load("queue_outlet_name"),
      queue_user_mobile: cookie.load("queue_mobile_no"),
      number_of_adult: cookie.load("queue_adult"),
      number_of_child: cookie.load("queue_child"),
      queue_no: cookie.load("QueueOrdernumber"),
      queue_id: cookie.load("QueueOrderlocalId"),      
    };

    var orderlocalId =
      typeof cookie.load("QueueOrdernumber") === "undefined"
        ? ""
        : cookie.load("QueueOrdernumber");
    if (orderlocalId === "") {
      this.props.history.push("/");
    }
    cookie.remove("defaultAvilablityId");
  }

  clear_queue_cookies() {
    cookie.remove("queue_adult");
    cookie.remove("queue_child");
    cookie.remove("queue_outlet_id");
    cookie.remove("queue_outlet_name");
    cookie.remove("queue_mobile_no");
    cookie.remove("queue_selected_outlet");
    cookie.remove("QueueOrderlocalId");
    cookie.remove("QueueOrdernumber");    
    cookie.remove("defaultAvilablityId");
  }

  componentDidMount() {
    this.clear_queue_cookies();
  }

  render() {

    return (
      <div>
        <Header />
        <div className="container innersection_wrap">
          <div className="queue_step_one">
            <h3>GEt MY Queue</h3>

            <div className="reservation-innermain">
              <div className="reservation-tnkyou-header">
                <img src={tickImg} alt="tick" />
                <h2>Thank You</h2>
                <h5>Your Queue Number Is</h5>
                <h1 className="tnk-orderno">Q No - {this.state.queue_no}</h1>

                <div className="res-tnkyou-btn">
                  <a href="/" className="button"> Go back </a>
                </div>
              </div>

              {/* <div className="thank-order-detaildiv">
                <div className="tnk-detail text-center">
                  <h2>YOUR RESERVATION DETAILS</h2>
                  <div className="tnk-order">
                    <h3>Q No - {this.state.queue_no}</h3>
                    <p>Order no : {this.state.queue_id}</p>
                  </div>
                </div>

                <div className="tnk-delivery">
                  <div className="delivery-cart-div">
                    <div className="cart_row cart-header-first">
                      <div className="queue_order_details">
                        <div className="queue_outlet">
                          <h4>Order Handling By</h4>
                          <p>{this.state.outletName}</p>
                        </div>
                        <br></br>
                        <div className="queue_outlet">
                          <h4>Phone No</h4>
                          <p>{this.state.queue_user_mobile}</p>
                        </div>
                        <br></br>
                        <div className="queue_user">
                          <h4>NUMBER OF PAX</h4>
                          <p>
                            {this.state.number_of_adult} Adult &{" "}
                            {this.state.number_of_child} Child
                          </p>
                        </div>
                        <br></br>
                        <div className="queue_notes">
                          <h4>Q No</h4>
                          <p>{this.state.queue_no}</p>
                        </div>
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>





          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Reservationqueuethankyou;
