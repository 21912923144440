/* eslint-disable */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import { appId } from "./components/Helpers/Config";

import Queue from "./components/Queue/Queue";
import Reservationqueuethankyou from "./components/Queue/Reservationqueuethankyou";

import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";
import cookie from "react-cookies";
import TagManager from "react-gtm-module";
const store = getStore();

var isSubdomain = function (url) {
  var regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  return !!url.match(regex);
};

var isSubdomains = function (url) {
  return url.split(".").length > 2;
};

if (
  !window.location.host.startsWith("www") &&
  !isSubdomain(window.location.host) &&
  !isSubdomains(window.location.host)
) {
  window.location =
    window.location.protocol +
    "//" +
    "www." +
    window.location.host +
    window.location.pathname;
}


render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Queue} />
        
        <Route path="/queue" component={Queue} />
        <Route path="/thankyou" component={Reservationqueuethankyou} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
